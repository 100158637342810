
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { IDateRange, IEventType, IFilter, IInitialCampaign, IPushCampaign } from '@/utils/types'
import { lastNMonth, now } from '@/utils/datetime'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { DatePicker },
  directives: { maska },
  emits: ['onChangeFilters'],
})
export default class LineUsers extends mixins(AppMixin) {
  @Prop()
  liveFilters!: IFilter[]

  @Prop()
  campaigns!: {}[]

  eventTypes: {}[] = []

  filterDateRange: IDateRange = {
    from: '',
    to: '',
  }

  filters: IFilter[] = []
  filterIndex = 0
  selectedCampaigns: (IInitialCampaign | IPushCampaign)[] = []
  selectedEvents: IEventType[] = []

  get filterTypes() {
    const opt = [
      { label: this.$t('label.users.campaign'), value: 'campaign' },
      { label: this.$t('label.users.event'), value: 'event' },
      { label: this.$t('label.users.signed_up_at'), value: 'signed_up_at' },
    ]
    return opt
  }

  onAddFilter(filterType) {
    const filter = this.initFilter(filterType)
    this.filters.push(filter)
    this.filterIndex = this.filters.length - 1
  }

  onEditFilter(filterType) {
    this.filters[this.filterIndex] = this.initFilter(filterType)
  }

  selectFilter(index) {
    this.filterIndex = index
  }

  onFilterChange() {
    this.$emit('onChangeFilters', this.filters)
  }

  duplicateFilter(index) {
    const item = this.filters[index]
    const filter: IFilter = {
      filter_type: item.filter_type,
      filter_value: item.filter_value,
      filter_multi_select: item.filter_multi_select,
      filter_select_all: item.filter_select_all,
    }
    this.filters.push(filter)
  }

  deleteFilter(index) {
    this.filters.splice(index, 1)

    if (this.filters.length === 0) {
      this.onFilterChange()
    }
  }

  changeFilterDateRange(dateRange) {
    this.filters.forEach((el, i) => {
      if (i === this.filterIndex) {
        el.filter_value = dateRange
      }
    })
  }

  initFilter(filterType) {
    const filter: IFilter = {
      filter_type: filterType,
      filter_value: {},
    }

    if (filterType === 'signed_up_at') {
      this.filterDateRange = {
        from: lastNMonth(1),
        to: now(),
      }
      filter.filter_value = this.filterDateRange
    } else {
      filter.filter_value = []
    }

    return filter
  }

  checkAllCampaigns(v) {
    if (v) {
      this.filters[this.filterIndex].filter_value = this.campaigns
      this.selectedCampaigns = this.campaigns
      this.setMultiSelectCampaign()
      return
    }
    this.campaignCleared()
  }

  checkAllEvents(v) {
    if (v) {
      this.filters[this.filterIndex].filter_value = this.eventTypes
      this.selectedEvents = this.eventTypes
      this.setMultiSelectEvent()
      return
    }
    this.eventCleared()
  }

  campaignSelected(v) {
    this.selectedCampaigns = v
    this.filters[this.filterIndex].filter_select_all = this.selectedCampaigns.length === this.campaigns.length
    this.setMultiSelectCampaign()
  }

  eventSelected(v) {
    this.selectedEvents = v
    this.filters[this.filterIndex].filter_select_all = this.selectedEvents.length === this.eventTypes.length
    this.setMultiSelectEvent()
  }

  campaignCleared() {
    this.filters[this.filterIndex].filter_select_all = false
    this.filters[this.filterIndex].filter_value = []
    this.selectedCampaigns = []
    this.campaignSelected([])
  }

  eventCleared() {
    this.filters[this.filterIndex].filter_select_all = false
    this.filters[this.filterIndex].filter_value = []
    this.selectedEvents = []
    this.eventSelected([])
  }

  setMultiSelectCampaign() {
    const selected = this.selectedCampaigns
    const titles = selected
      .map((item) => item.title)
      .join(', ')
      .split(', ')
    if (titles.length > 2) {
      this.filters[this.filterIndex].filter_multi_select = titles.splice(0, 2).join(', ') + ' and more...'
    } else {
      this.filters[this.filterIndex].filter_multi_select = titles.join(', ')
    }
  }

  setMultiSelectEvent() {
    const selected = this.selectedEvents
    const titles = selected
      .map((item) => item.label)
      .join(', ')
      .split(', ')
    if (titles.length > 2) {
      this.filters[this.filterIndex].filter_multi_select = titles.splice(0, 2).join(', ') + ' and more...'
    } else {
      this.filters[this.filterIndex].filter_multi_select = titles.join(', ')
    }
  }

  mounted() {
    this.filters = this.liveFilters
    const opt = [
      { label: this.$t('label.users.delivered'), value: 'delivered' },
      { label: this.$t('label.users.answered'), value: 'answered' },
      { label: this.$t('label.users.sent_free_message'), value: 'sent_free_message' },
      { label: this.$t('label.users.contents_clicked'), value: 'contents_clicked' },
      { label: this.$t('label.users.cv'), value: 'cv' },
    ]
    opt.forEach(item => {
      this.eventTypes.push(item)
    })
  }
}
