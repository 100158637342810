
import CLoading from '@/components/common/ui/CLoading.vue'
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import { Watch } from 'vue-property-decorator'
import { lastNMonth, now } from '@/utils/datetime'
import { ACTION_ACTIVITY_LOG, ACTION_APP_USER, ACTION_TARGET_SETTING } from '@/store/actions'
import { IActivityLog, IAppUser, IDateRange } from '@/utils/types'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import UserPanel from '@/components/app/UserPanel.vue'
import ActivityLog from '@/components/app/ActivityLog.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { ActivityLog, UserPanel, DatePicker, CLoading },
  directives: { maska },
})
export default class LineUserProfile extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  firstLoad = true
  selectAll = false
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  hideEvents: string[] = []

  user: IAppUser = {}
  rawData: IActivityLog[] = []
  data: {}[] = []
  lastActivity: {} = {}
  currentPage = 1
  totalPages = 0

  get events() {
    const opt = [
      { label: this.$t('label.users.registered_channel'), value: 'registered_channel' },
      { label: this.$t('label.users.delivered'), value: 'delivered' },
      { label: this.$t('label.users.answered'), value: 'answered' },
      { label: this.$t('label.users.sent_free_message'), value: 'sent_free_message' },
      { label: this.$t('label.users.contents_clicked'), value: 'contents_clicked' },
      { label: this.$t('label.users.cv'), value: 'cv' },
    ]

    return opt
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedUserId() {
    return this.$route.params.user_id
  }

  get selectedUId() {
    return this.$route.params.uid
  }

  get isTestMode() {
    return this.$route.params.is_test_mode
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.user = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEM, this.selectedUId)

    await this.loadInitials()
    this.firstLoad = false
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value

    this.loadInitials()
  }

  async loadInitials(newPage = false) {
    this.loading = true
    let dateFrom
    let dateTo
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        dateFrom = this.dateRange
        dateTo = this.dateRange
      } else {
        dateFrom = this.dateRange.from
        dateTo = this.dateRange.to
      }
    }

    const activityLogData = await this.$store.dispatch(ACTION_ACTIVITY_LOG.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      user_id: this.selectedUserId,
      date_from: dateFrom,
      date_to: dateTo,
      is_test_mode: this.isTestMode,
      filter: {
        page: this.currentPage,
        per_page: 50,
        hide_events: this.hideEvents,
      },
    })

    if (this.totalPages !== activityLogData.total_pages) {
      this.totalPages = activityLogData.total_pages
    }

    for (const item of activityLogData.items) {
      item.date = item.created_at.split(' ')[0]
      item.time = item.created_at.split(' ')[1]
      item.event_data = [
        {
          _id: item._id,
          name: item.event_type,
          value: item.event_value,
          resource_id: item.resource_id,
          is_test_mode: item.is_test_mode,
          resource_type: item.resource_type,
          url: '',
          loading: false,
        },
      ]
    }

    if (newPage) {
      this.rawData = this.rawData.concat(activityLogData.items)
    } else {
      this.rawData = activityLogData.items
    }
    this.lastActivity = this.rawData[0]
    this.data = this.buildData(this.rawData)

    this.loading = false
  }

  checkAll(v) {
    if (v) {
      this.hideEvents = this.events.map((v) => v.value)
      this.eventSelected()
      return
    }
    this.eventCleared()
  }

  eventSelected() {
    this.selectAll = this.hideEvents.length === this.events.length
  }

  eventCleared() {
    this.selectAll = false
    this.hideEvents = []
    this.eventSelected()
  }

  eventApplied() {
    console.log('eventApplied')

    this.$refs.hide_events.hidePopup()
    this.$refs.hide_events.focused = false
    this.loadInitials()
  }

  groupBy(list, key) {
    return list.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  buildData(data) {
    const mergedCampaign = data.reduce((r, { date, campaign_type, campaign_name, ...rest }) => {
      const key = `${date}-${campaign_type}-${campaign_name}`
      r[key] = r[key] || { date, campaign_type, campaign_name, events: [] }
      r[key].events.push(rest)
      return r
    }, {})

    const merged = this.groupBy(Object.values(mergedCampaign), 'date')
    return Object.entries(merged)
  }

  loadMore() {
    this.currentPage++
    this.loadInitials(true)
  }
}
