
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_APP_USER, ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Formater } from '@/utils/formater'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'

import UserAvatar from '@/components/user/UserAvatar.vue'
import { IAppUser, IDateRange, IFilter, IInitialCampaign, IPushCampaign, IUser } from '@/utils/types'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import UserFilters from '@/components/app/UserFilters.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { UserFilters, CLoading, TestModeSelector, UserAvatar },
  directives: { maska },
})
export default class LineUsers extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  rows = []
  isTestMode = false
  pagination = {
    sortBy: 'updated_at',
    descending: true,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  filters: IFilter[] = []
  pushCampaigns: IPushCampaign[] = []
  initialCampaigns: IInitialCampaign[] = []
  campaigns: {}[] = []

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  @Watch('isTestMode')
  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.pushCampaigns = await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
      target_setting_ids: ['all'],
    })
    this.pushCampaigns.forEach((element) => {
      this.campaigns.push({
        type: 'push',
        _id: element._id,
        title: element.title,
      })
    })
    this.initialCampaigns = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
      target_setting_ids: ['all'],
    })
    this.initialCampaigns.forEach((element) => {
      this.campaigns.push({
        type: 'initial',
        _id: element._id,
        title: element.title,
      })
    })

    this.$nextTick(() => {
      // get initial data from server (1st page)
      this.$refs.tableRef.requestServerInteraction()
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loading = true
    const returnedData = await this.fetchFromServer(page, rowsPerPage, filter, sortBy, descending)
    if (returnedData) {
      // eslint-disable-next-line
      const items: [] = returnedData['items']
      this.rows.splice(0, this.rows.length, ...items)
      // eslint-disable-next-line
      this.pagination.rowsNumber = returnedData['total_items']
    }
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending

    this.loading = false
  }

  async fetchFromServer(page, rowsPerPage, filter, sortBy, descending) {
    const items = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        page: page,
        per_page: rowsPerPage,
        filter: filter,
        user_filter: this.filters,
        sort_by: sortBy,
        descending: descending,
      },
    })

    return items
  }

  onProfile(data: IAppUser) {
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }
    this.goto('app_line_user_profile', {
      app_id: this.selectedAppId,
      user_id: data.user_id,
      uid: data._id,
      from_date: date_from,
      to_date: date_to,
      is_test_mode: this.isTestMode,
    })
  }

  changeFilters(filters) {
    this.filters = filters

    this.$nextTick(() => {
      // get initial data from server (1st page)
      this.$refs.tableRef.requestServerInteraction()
    })
  }
}
