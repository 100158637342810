import { render } from "./UserPanel.vue?vue&type=template&id=52e3d83e&scoped=true"
import script from "./UserPanel.vue?vue&type=script&lang=ts"
export * from "./UserPanel.vue?vue&type=script&lang=ts"

import "./UserPanel.vue?vue&type=style&index=0&id=52e3d83e&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-52e3d83e"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QIcon,QExpansionItem,QAvatar,QItem});
