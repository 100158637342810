import { render } from "./UserFilters.vue?vue&type=template&id=2c767e98&scoped=true"
import script from "./UserFilters.vue?vue&type=script&lang=ts"
export * from "./UserFilters.vue?vue&type=script&lang=ts"

import "./UserFilters.vue?vue&type=style&index=0&id=2c767e98&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-2c767e98"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QIcon,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QSelect,QCheckbox,QBtn,QSpace});qInstall(script, 'directives', {ClosePopup});
