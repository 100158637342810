
import { Options, Vue } from 'vue-class-component'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { maska } from 'maska'
import { IAppUser, IUser } from '@/utils/types'
import moment from 'moment'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { UserAvatar },
  directives: { maska },
})
export default class UserPanel extends Vue {
  @Prop()
  user!: IAppUser

  getUserInfo(displayName, pictureUrl) {
    const u: IUser = {
      photo_url: pictureUrl,
      display_name: displayName,
    }
    return u
  }

  displayTimeFormat(value, inputFormat, outputFormat) {
    return moment(value, inputFormat).format(outputFormat)
  }
}
