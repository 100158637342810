<template>
  <q-card>
    <q-card-section class="column q-pl-none q-pr-none">
      <div class="column q-pa-md border-bottom text-center">
        <div class="col col-avatar">
          <UserAvatar :user="getUserInfo(user.display_name, user.picture_url)" />
        </div>
        <div class="col text-bold text-h6">
          {{ user.display_name }}
        </div>
        <div class="col text-caption">
          {{ user.email }}
        </div>
      </div>
      <div class="row q-pa-md border-bottom" style="width: 100%">
        <div class="col">
          <q-icon class="text-h5" name="portrait" />
        </div>
        <div class="column col-11">
          <div class="col">
            {{ $t('label.audience_user.user_id') }}
          </div>
          <q-expansion-item class="text-overline uid-span" :label="user.user_id">
            <q-card>
              <q-card-section class="text-caption q-pa-none">
                {{ user.user_id }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </div>
      </div>
      <div class="row q-pa-md">
        <div class="col">
          <q-icon class="text-h5" name="event" />
        </div>
        <div class="column col-11">
          <div class="col">
            {{ $t('label.push.updated_at') }}
          </div>
          <div class="col-auto">
            {{ displayTimeFormat(user.updated_at, 'YYYY-MM-DD HH:mm:ii', 'MMM DD, YYYY') }}
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { maska } from 'maska'
import { IAppUser, IUser } from '@/utils/types'
import moment from 'moment'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { UserAvatar },
  directives: { maska },
})
export default class UserPanel extends Vue {
  @Prop()
  user!: IAppUser

  getUserInfo(displayName, pictureUrl) {
    const u: IUser = {
      photo_url: pictureUrl,
      display_name: displayName,
    }
    return u
  }

  displayTimeFormat(value, inputFormat, outputFormat) {
    return moment(value, inputFormat).format(outputFormat)
  }
}
</script>

<style scoped lang="scss">
.uid-span {
  width: 100%;
  word-wrap: break-word;
}
:deep(.col-avatar .q-avatar) {
  font-size: 96px !important;
  margin-top: -90px;
}
:deep(.q-expansion-item .q-item) {
  padding-left: 0;
  padding-right: 0;
}
:deep(.q-expansion-item__container .q-hoverable:hover .q-focus-helper) {
  background: inherit;
  opacity: 0;
}
:deep(.col-xs-12 label.items-start) {
  width: 100% !important;
  margin-right: 0;
  margin-bottom: 8px;
}
</style>
