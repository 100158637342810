
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment/moment'
import { IActivityLog } from '@/utils/types'
import { ACTION_ACTIVITY_LOG } from '@/store/actions'

@Options({})
export default class ActivityLog extends Vue {
  @Prop()
  data!: {}[]

  @Prop()
  lastActivity!: {}

  get selectedAppId() {
    return this.$route.params.app_id
  }

  displayTimeFormat(value, inputFormat, outputFormat) {
    return moment(value, inputFormat).format(outputFormat)
  }

  async getContentURL(item) {
    // data.event_value === 'video' || data.event_value === 'image' || data.event_value === 'file'
    if (
      (item.resource_type === 'image' ||
        item.resource_type === 'video' ||
        item.resource_type === 'audio' ||
        item.resource_type === 'file') &&
      item.event_data[0].url === ''
    ) {
      item.event_data[0].loading = true
      const url = await this.getContentData(item)
      item.event_data[0].url = url
      item.event_data[0].loading = false
    }
  }

  async getContentData(data: IActivityLog) {
    let url = ''
    if (data.resource_id && data.resource_type === 'image') {
      const val = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_ARRAY_BUFFER, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      const base64Data = btoa(String.fromCharCode(...new Uint8Array(val)))
      url = `data:image/jpeg;base64,${base64Data}`
    } else if (data.resource_id && (data.resource_type === 'video' || data.resource_type === 'audio')) {
      const videoBlob = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_BLOD, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      url = URL.createObjectURL(videoBlob)
    } else if (data.resource_id && data.resource_type === 'file') {
      const blob = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_BLOD, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      url = URL.createObjectURL(blob)
    }

    return url
  }
}
