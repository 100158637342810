<template>
  <div class="full-width q-pt-md q-pb-md row q-gutter-md">
    <q-card class="full-width">
      <q-card-section class="column q-gutter-sm">
        <div class="row">
          <q-icon class="text-weight-bold text-grey-8 q-mr-xs" name="drag_indicator" />
          <span class="text-uppercase text-weight-bold text-grey-8" style="font-size: 12px">{{
            $t('label.users.all_users')
          }}</span>
        </div>
        <div class="column" v-if="filters.length">
          <div class="row q-mt-sm q-ml-sm" v-for="(filter, index) in filters" :key="index" @click="selectFilter(index)">
            <div class="filter-span row">
              <span class="q-pa-sm w-full" v-if="index === 0">where</span>
              <span class="q-pa-sm w-full" v-else>and</span>
            </div>
            <div class="filter-content row" v-if="filter.filter_type === 'campaign'">
              <q-btn-dropdown
                flat
                color="primary"
                text-color="grey-9"
                class="q-pa-sm q-ml-sm"
                :label="$t('label.users.campaign')"
                menu-anchor="bottom left"
                menu-self="top left"
                dropdown-icon="none"
              >
                <q-list>
                  <q-item
                    v-for="(filterType, index) in filterTypes"
                    :key="index"
                    clickable
                    v-close-popup
                    @click="onEditFilter(filterType.value)"
                  >
                    <q-item-section>
                      <q-item-label>{{ filterType.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <span class="q-pa-sm q-mr-sm">is</span>
              <q-select
                v-model="filter.filter_value"
                :options="campaigns"
                :label="$t('label.users.select_campaign')"
                outlined
                dense
                options-dense
                multiple
                option-value="_id"
                option-label="title"
                class="q-mr-md"
                style="min-width: 240px; max-width: 480px"
              >
                <template v-slot:selected>
                  <div>{{ filter.filter_multi_select }}</div>
                </template>
                <template #before-options>
                  <q-item>
                    <q-checkbox v-model="filter.filter_select_all" @update:model-value="checkAllCampaigns">{{
                      $t('label.users.select_all')
                    }}</q-checkbox>
                  </q-item>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-checkbox
                      v-model="filter.filter_value"
                      :val="scope.opt"
                      @update:model-value="campaignSelected"
                      style="width: 100%"
                      >{{ scope.opt.title }}</q-checkbox
                    >
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="filter-content row" v-else-if="filter.filter_type === 'event'">
              <q-btn-dropdown
                flat
                color="primary"
                text-color="grey-9"
                class="q-pa-sm q-ml-sm"
                :label="$t('label.users.event')"
                menu-anchor="bottom left"
                menu-self="top left"
                dropdown-icon="none"
              >
                <q-list>
                  <q-item
                    v-for="(filterType, index) in filterTypes"
                    :key="index"
                    clickable
                    v-close-popup
                    @click="onEditFilter(filterType.value)"
                  >
                    <q-item-section>
                      <q-item-label>{{ filterType.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <span class="q-pa-sm q-mr-sm">is</span>
              <q-select
                v-model="filter.filter_value"
                :options="eventTypes"
                :label="$t('label.users.select_event')"
                outlined
                dense
                options-dense
                multiple
                option-value="value"
                option-label="label"
                class="q-mr-md"
                style="min-width: 240px; max-width: 480px"
              >
                <template v-slot:selected>
                  <div>{{ filter.filter_multi_select }}</div>
                </template>
                <template #before-options>
                  <q-item>
                    <q-checkbox v-model="filter.filter_select_all" @update:model-value="checkAllEvents">{{
                        $t('label.users.select_all')
                      }}</q-checkbox>
                  </q-item>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-checkbox
                      v-model="filter.filter_value"
                      :val="scope.opt"
                      @update:model-value="eventSelected"
                      style="width: 100%"
                    >{{ scope.opt.label }}</q-checkbox
                    >
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="filter-content row" v-else-if="filter.filter_type === 'signed_up_at'">
              <q-btn-dropdown
                flat
                color="primary"
                text-color="grey-9"
                class="q-pa-sm q-ml-sm"
                :label="$t('label.users.signed_up_at')"
                menu-anchor="bottom left"
                menu-self="top left"
                dropdown-icon="none"
              >
                <q-list>
                  <q-item
                    v-for="(filterType, index) in filterTypes"
                    :key="index"
                    clickable
                    v-close-popup
                    @click="onEditFilter(filterType.value)"
                  >
                    <q-item-section>
                      <q-item-label>{{ filterType.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <span class="q-pa-sm q-mr-sm">at</span>
              <DatePicker
                class="q-mr-md"
                :default-date-range="filter.filter_value"
                :disable="false"
                @update:onChangeDateRange="changeFilterDateRange"
              ></DatePicker>
            </div>
            <q-btn
              flat
              color="primary"
              text-color="grey-9"
              icon="content_copy"
              class="q-pa-sm"
              @click="duplicateFilter(index)"
            />
            <q-btn
              flat
              color="primary"
              text-color="grey-9"
              icon="delete_forever"
              class="q-pa-sm q-ml-sm"
              @click="deleteFilter(index)"
            />
          </div>
        </div>
        <div class="row justify-between">
          <q-btn-dropdown
            flat
            menu-anchor="bottom left"
            menu-self="top left"
            color="primary"
            text-color="grey-9"
            icon="add"
            :label="$t('label.users.filter')"
            dropdown-icon="none"
            class="q-ml-sm"
          >
            <q-list>
              <q-item
                v-for="(filterType, index) in filterTypes"
                :key="index"
                clickable
                v-close-popup
                @click="onAddFilter(filterType.value)"
              >
                <q-item-section>
                  <q-item-label>{{ filterType.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>

          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn v-if="filters.length" no-caps :label="$t('apply')" color="primary" @click="onFilterChange" />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { IDateRange, IEventType, IFilter, IInitialCampaign, IPushCampaign } from '@/utils/types'
import { lastNMonth, now } from '@/utils/datetime'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { DatePicker },
  directives: { maska },
  emits: ['onChangeFilters'],
})
export default class LineUsers extends mixins(AppMixin) {
  @Prop()
  liveFilters!: IFilter[]

  @Prop()
  campaigns!: {}[]

  eventTypes: {}[] = []

  filterDateRange: IDateRange = {
    from: '',
    to: '',
  }

  filters: IFilter[] = []
  filterIndex = 0
  selectedCampaigns: (IInitialCampaign | IPushCampaign)[] = []
  selectedEvents: IEventType[] = []

  get filterTypes() {
    const opt = [
      { label: this.$t('label.users.campaign'), value: 'campaign' },
      { label: this.$t('label.users.event'), value: 'event' },
      { label: this.$t('label.users.signed_up_at'), value: 'signed_up_at' },
    ]
    return opt
  }

  onAddFilter(filterType) {
    const filter = this.initFilter(filterType)
    this.filters.push(filter)
    this.filterIndex = this.filters.length - 1
  }

  onEditFilter(filterType) {
    this.filters[this.filterIndex] = this.initFilter(filterType)
  }

  selectFilter(index) {
    this.filterIndex = index
  }

  onFilterChange() {
    this.$emit('onChangeFilters', this.filters)
  }

  duplicateFilter(index) {
    const item = this.filters[index]
    const filter: IFilter = {
      filter_type: item.filter_type,
      filter_value: item.filter_value,
      filter_multi_select: item.filter_multi_select,
      filter_select_all: item.filter_select_all,
    }
    this.filters.push(filter)
  }

  deleteFilter(index) {
    this.filters.splice(index, 1)

    if (this.filters.length === 0) {
      this.onFilterChange()
    }
  }

  changeFilterDateRange(dateRange) {
    this.filters.forEach((el, i) => {
      if (i === this.filterIndex) {
        el.filter_value = dateRange
      }
    })
  }

  initFilter(filterType) {
    const filter: IFilter = {
      filter_type: filterType,
      filter_value: {},
    }

    if (filterType === 'signed_up_at') {
      this.filterDateRange = {
        from: lastNMonth(1),
        to: now(),
      }
      filter.filter_value = this.filterDateRange
    } else {
      filter.filter_value = []
    }

    return filter
  }

  checkAllCampaigns(v) {
    if (v) {
      this.filters[this.filterIndex].filter_value = this.campaigns
      this.selectedCampaigns = this.campaigns
      this.setMultiSelectCampaign()
      return
    }
    this.campaignCleared()
  }

  checkAllEvents(v) {
    if (v) {
      this.filters[this.filterIndex].filter_value = this.eventTypes
      this.selectedEvents = this.eventTypes
      this.setMultiSelectEvent()
      return
    }
    this.eventCleared()
  }

  campaignSelected(v) {
    this.selectedCampaigns = v
    this.filters[this.filterIndex].filter_select_all = this.selectedCampaigns.length === this.campaigns.length
    this.setMultiSelectCampaign()
  }

  eventSelected(v) {
    this.selectedEvents = v
    this.filters[this.filterIndex].filter_select_all = this.selectedEvents.length === this.eventTypes.length
    this.setMultiSelectEvent()
  }

  campaignCleared() {
    this.filters[this.filterIndex].filter_select_all = false
    this.filters[this.filterIndex].filter_value = []
    this.selectedCampaigns = []
    this.campaignSelected([])
  }

  eventCleared() {
    this.filters[this.filterIndex].filter_select_all = false
    this.filters[this.filterIndex].filter_value = []
    this.selectedEvents = []
    this.eventSelected([])
  }

  setMultiSelectCampaign() {
    const selected = this.selectedCampaigns
    const titles = selected
      .map((item) => item.title)
      .join(', ')
      .split(', ')
    if (titles.length > 2) {
      this.filters[this.filterIndex].filter_multi_select = titles.splice(0, 2).join(', ') + ' and more...'
    } else {
      this.filters[this.filterIndex].filter_multi_select = titles.join(', ')
    }
  }

  setMultiSelectEvent() {
    const selected = this.selectedEvents
    const titles = selected
      .map((item) => item.label)
      .join(', ')
      .split(', ')
    if (titles.length > 2) {
      this.filters[this.filterIndex].filter_multi_select = titles.splice(0, 2).join(', ') + ' and more...'
    } else {
      this.filters[this.filterIndex].filter_multi_select = titles.join(', ')
    }
  }

  mounted() {
    this.filters = this.liveFilters
    const opt = [
      { label: this.$t('label.users.delivered'), value: 'delivered' },
      { label: this.$t('label.users.answered'), value: 'answered' },
      { label: this.$t('label.users.sent_free_message'), value: 'sent_free_message' },
      { label: this.$t('label.users.contents_clicked'), value: 'contents_clicked' },
      { label: this.$t('label.users.cv'), value: 'cv' },
    ]
    opt.forEach(item => {
      this.eventTypes.push(item)
    })
  }
}
</script>

<style scoped lang="scss">
.filter-span {
  width: 60px;
  text-align: right;
  align-items: center;
}
.filter-content {
  align-items: center;
}
:deep(.filter-content .q-btn) {
  text-transform: unset;
}
:deep(.filter-content i.q-btn-dropdown__arrow-container) {
  display: none;
}
</style>
